"use client"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { SessionProvider } from "next-auth/react"
import { ApolloProvider, ApolloClient, InMemoryCache } from "@apollo/client"
import { PropsWithChildren } from "react"
import { Toaster as SonnerToaster } from "sonner"
import { type Session } from "next-auth"
import { AuthModalProvider } from "@/hooks/use-auth-modal"

const queryCilent = new QueryClient()

const client = new ApolloClient({
  uri: "https://cms-website.edify.club/graphql",
  cache: new InMemoryCache(),
})

type ProvidersType = PropsWithChildren & { session: Session | null }

export const Providers = ({ children, session }: ProvidersType) => {
  return (
    <ApolloProvider client={client}>
      <QueryClientProvider client={queryCilent}>
        <SessionProvider
          session={session}
          refetchOnWindowFocus={false}
          refetchWhenOffline={false}
          refetchInterval={1500}
        >
          <AuthModalProvider>{children}</AuthModalProvider>
        </SessionProvider>
        <SonnerToaster toastOptions={{ className: "font-gilroySemiBold" }} />
      </QueryClientProvider>
    </ApolloProvider>
  )
}
