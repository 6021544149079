"use client"
import dynamic from "next/dynamic"

const DynamicLoader = dynamic(
  () => import("./loader.wrapper").then((mod) => mod.default),
  { ssr: false }
)

export default function Loader(props: {
  size?: number
  speed?: number
  color?: string
}) {
  return <DynamicLoader {...props} />
}
