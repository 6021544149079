import(/* webpackMode: "eager" */ "/application/src/app/globals.css");
;
import(/* webpackMode: "eager" */ "/application/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"../../public/fonts/gilroy/Gilroy_Regular.ttf\",\"display\":\"block\",\"variable\":\"--font-gilroy-regular\"}],\"variableName\":\"gilroyRegular\"}");
;
import(/* webpackMode: "eager" */ "/application/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"../../public/fonts/gilroy/Gilroy_Medium.ttf\",\"display\":\"block\",\"variable\":\"--font-gilroy-medium\"}],\"variableName\":\"gilroyMedium\"}");
;
import(/* webpackMode: "eager" */ "/application/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"../../public/fonts/gilroy/Gilroy_Semibold.ttf\",\"display\":\"block\",\"variable\":\"--font-gilroy-semibold\"}],\"variableName\":\"gilroySemiBold\"}");
;
import(/* webpackMode: "eager" */ "/application/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"../../public/fonts/gilroy/Gilroy_Bold.ttf\",\"display\":\"block\",\"variable\":\"--font-gilroy-bold\"}],\"variableName\":\"gilroyBold\"}");
;
import(/* webpackMode: "eager" */ "/application/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"../../public/fonts/orange-squash/Orange_Squash_Demo.ttf\",\"display\":\"block\",\"variable\":\"--font-orange\"}],\"variableName\":\"orange\"}");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/application/src/lib/providers.tsx");
